import { useEffect, useRef, useState } from 'preact/hooks';
import Latest from '@vgno/widgets/LatestV2';
import { Button } from '@vgno/core';

import styles from './Latest.module.scss';

export const ShowMoreButton = ({ onClick, loading, moreDataLoaded }) => {
    return (
        <div
            className={`${styles.showMoreButton} ${
                moreDataLoaded.value ? styles.moreDataLoaded : ''
            }`}
        >
            <Button
                style="filled"
                shape="capsule"
                onClick={onClick}
                disabled={loading}
                resize="fill"
            >
                <span>{loading ? 'Laster...' : 'Vis mer'}</span>
            </Button>
        </div>
    );
};

export const CollapsedButton = ({ onClick, loading }) => {
    return (
        <div className={styles.collapseButton}>
            <Button
                style="gray"
                shape="capsule"
                onClick={onClick}
                disabled={loading}
                resize="fill"
            >
                <span>Vis mindre</span>
            </Button>
        </div>
    );
};

export const LatestLoader = ({
    data,
    rootNode,
    loadMoreCallback,
    collapseCallback,
    device,
    loading,
    moreDataLoaded,
}) => {
    const observerRef = useRef(null);
    const [scrolledOnce, setScrolledOnce] = useState(false);
    useEffect(() => {
        if (device.isMobile()) {
            return;
        }

        const handleScroll = () => {
            if (!scrolledOnce) {
                setScrolledOnce(true);
            }
        };
        rootNode
            .querySelector('ol')
            .addEventListener('scroll', handleScroll, { once: true });

        return () => {
            rootNode
                .querySelector('ol')
                .removeEventListener('scroll', handleScroll, { once: true });
        };
    }, []);

    useEffect(() => {
        const onPointerUp = () => null;
        rootNode.addEventListener('pointerup', onPointerUp);
        return () => {
            rootNode.removeEventListener('pointerup', onPointerUp);
        };
    }, []);

    useEffect(() => {
        if (observerRef.current || !scrolledOnce) {
            return;
        }

        if (device.isMobile() || loading.value) {
            return;
        }

        observerRef.current = new IntersectionObserver(
            (entries) => {
                const [entry] = entries;
                if (entry.isIntersecting) {
                    loadMoreCallback();
                }
            },
            {
                rootMargin: '40% 0px',
                root: rootNode.querySelector('ol'),
            },
        );
        observerRef.current.observe(
            rootNode.querySelector('.vg-widget-latest-sentinel'),
        );
    }, [loading, scrolledOnce]);

    const props = {
        queryParams: {
            utm_source: 'vgfront',
            utm_content: 'vg-widget-latest',
        },
    };

    let showMoreButton = null;
    let collapseButton = null;

    if (device.isMobile()) {
        showMoreButton = (
            <ShowMoreButton
                onClick={loadMoreCallback}
                loading={loading.value}
                moreDataLoaded={moreDataLoaded}
            />
        );
    }

    if (device.isMobile() && moreDataLoaded.value) {
        collapseButton = (
            <CollapsedButton
                onClick={collapseCallback}
                loading={loading.value}
            />
        );
    }

    return (
        <>
            <Latest data={data.value} {...props} />
            {showMoreButton}
            {collapseButton}
        </>
    );
};
